import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useState, memo, useCallback, useEffect, } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import CheckboxComponent from '@shared/ui/inputs/Checkbox';
import { BodySemiBold } from '@components/styled/Typography';
const CheckboxGroupContainer = styled('div', { shouldForwardProp: propName => isPropValid(propName) })(props => ({
    width: props.fullWidth ? '100%' : 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: `${props.theme.spacing_sizes.xs}px`,
}));
const Title = styled(BodySemiBold)((props) => ({
    marginBottom: `${props.theme.spacing_sizes.xs / 4}px`,
}));
const Checkbox = memo(CheckboxComponent);
const CheckboxGroup = ({ onChange = () => { }, options = [], defaultActiveCheckboxes = [], title = undefined, fullWidth = false, }) => {
    const [activeKeys, setActiveKeys] = useState(defaultActiveCheckboxes);
    const handleCheckboxChange = useCallback((key) => (event) => {
        setActiveKeys((prevKeys) => {
            var _a;
            const isActive = (_a = event.target) === null || _a === void 0 ? void 0 : _a.checked;
            const updatedKeys = isActive ?
                [...prevKeys, key] :
                prevKeys.filter((option) => option !== key);
            return updatedKeys;
        });
    }, [setActiveKeys]);
    useEffect(() => {
        if (onChange) {
            onChange(activeKeys);
        }
    }, [activeKeys]);
    return (_jsxs(CheckboxGroupContainer, { role: 'list', fullWidth: fullWidth, children: [title ? _jsx(Title, { children: title }) : null, (options === null || options === void 0 ? void 0 : options.length) > 0 ? options.map((option) => (_createElement(Checkbox
            // eslint-disable-next-line react/jsx-props-no-spreading
            , Object.assign({}, option, { role: 'listitem', key: option.value, onChange: handleCheckboxChange(option.value), fullWidth: fullWidth })))) : null] }));
};
export default CheckboxGroup;
